(function($) {
    const fn = $("#layout_header");

    if (fn.length) {
        let header_top = fn.find(".wrp_header_top");
        let header = fn.find(".wrp_header_body");

        fn.after('<nav id="layout_nav" class="ssm-nav" role="presentation"><div class="elm_head"></div><div class="elm_content"><div class="elm_top">\n' +
            '            <a class="icon icon--arrow-left" data-submenu-back></a>\n' +
            '            <ul class="elm_breadcrumbs" data-menu-breadcrumbs>\n' +
            '                <li class="mod--active" data-menu-id="main">Menu</li>\n' +
            '            </ul>\n' +
            '        </div></div></nav>');

        let layout_nav = $(document).find("#layout_nav"),
            lang = header_top.find(".elm_lang").clone(),
            info = header_top.find(".elm_info").clone(),
            logo = header.find(".elm_logo").clone(),
            nav = header.find(".elm_nav").clone();

        layout_nav.find(".elm_head").append(logo);
        layout_nav.find(".elm_content").prepend(lang);
        layout_nav.find(".elm_content").append(nav);
        layout_nav.find(".elm_content").append(info);

        $.getScript(cdnjs.touchswipe).done(()=> {
            $('.ssm-nav').slideAndSwipe();
        });

        $.getScript(cdnjs.headroom).done(()=> {
            let headroom = new Headroom(fn[0], {
                offset: 46,
                tolerance : {
                    up : 5,
                    down : 0
                },
                classes: {
                    "initial" : "headroom",
                    "pinned": "headroom--pinned",
                    "unpinned": "headroom--unpinned",
                    "top" : "headroom--top",
                    "notTop" : "headroom--not-top"
                }
            });
            headroom.init();
        });

        (function nav_menu(){
            let nav = $("#layout_nav");

            let animation_delay = 60,
                animation_duration = 600;

            $.fn.fn_animate = function(menu_id,direction,val) {
                let el = $(this),
                    animation_delay = el.parent().find("[data-menu=\""+menu_id+"\"]").data("menu-delay") + animation_duration;

                let anim_out, anim_in;
                if (direction === "in") {
                    anim_out = "animate--out-left";
                    anim_in = "animate--in-right"
                } else {
                    anim_out = "animate--out-right";
                    anim_in = "animate--in-left"
                }

                el.closest(".elm_content").addClass("mod--animated");

                el.addClass(anim_out).parent().find("[data-menu=\""+menu_id+"\"]").addClass(anim_in);


                if (nav.find("[data-menu-breadcrumbs]").children("li").length === 1) {
                    nav.find("[data-submenu-back]").removeClass("mod--active");
                }

                setTimeout(function(){
                    el.removeClass(anim_out+" mod--active").parent().find("[data-menu=\""+menu_id+"\"]").removeClass(anim_in).addClass("mod--active");
                    el.closest(".elm_content").removeClass("mod--animated");
                    if (direction === "in") {
                        nav.find("[data-menu-breadcrumbs]").children("li").removeClass("mod--active").parent().append("<li class='mod--active' data-menu-id='"+menu_id+"'>"+val+"</li>").find("mod--active");
                    }

                    if (nav.find("[data-menu-breadcrumbs]").children("li").length > 1) {
                        nav.find("[data-submenu-back]").addClass("mod--active");
                    }
                }, animation_delay);
            };

            nav.find("[data-menu]").each(function() {
                let el = $(this),
                    el_length = el.children("li").length - 1;

                el.children("li").each(function(i){
                    $(this).css("animation-delay", i*animation_delay+"ms");
                });

                el.attr("data-menu-delay", el_length * animation_delay);
            });


            nav.on("click", "[data-menu-breadcrumbs] [data-menu-id]", function() {
                let el = $(this),
                    menu_active = nav.find("[data-menu].mod--active"),
                    menu_id = el.data("menu-id");

                if (!el.hasClass("mod--active")) {
                    el.addClass("mod--active").nextAll().remove();
                    menu_active.fn_animate(menu_id, "out");
                }
            });

            nav.on("click", "[data-submenu]", function(e){
                e.preventDefault();
                let el = $(this),
                    val = el.prev().find("span").text(),
                    menu_id = el.data("submenu");

                el.closest("[data-menu]").fn_animate(menu_id, "in", val);
            });

            nav.on("click", "[data-submenu-back]", function(e){
                e.preventDefault();
                let menu = nav.find("[data-menu].mod--active"),
                    menu_prev = $("[data-menu-breadcrumbs] [data-menu-id].mod--active").prev(),
                    menu_id = menu_prev.data("menu-id");

                menu_prev.addClass("mod--active").nextAll().remove();
                menu.fn_animate(menu_id, "out");
            });
        })();
    }
})(jQuery);