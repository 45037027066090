(function($) {
    fn_nl_lib_anchor();

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
    });

    $(".part_ui_btn").nl_lib_ripple();

    nl_lib_dialog.init();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        var formButton = $(this).find('button[type="submit"]');
        formButton.attr('disabled','disabled');

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            },
            error: function(jqXHR, textStatus, errorThrown ) {
                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            }
        });
    });
    
    doc.on('submit', 'form[data-component-ajax]', function (e) {
        e.preventDefault();
        let frm = $(this),
            formData = new FormData($(this)[0]);

        var formButton = $(this).find('button[type="submit"]');
        formButton.attr('disabled','disabled');

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {

                fn_ajaxHandler(payload);
                sr.sync();
                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            },
            error: function(jqXHR, textStatus, errorThrown ) {
                setTimeout(function() {
                    formButton.removeAttr('disabled');
                }, 1000);
            }
        });
    });

    const gallery = $("[data-global-gallery]");
    if (gallery.length) {
        $.getScript(cdnjs.lightgallery).done(function(){
            gallery.lightGallery({
                thumbnail: true,
                selector: ".lib--gallery-item",
                exThumbImage: 'data-exthumbimage',
                fullScreen: false,
                zoom: true,
                actualSize: false,
                hash: true,
                download: true,
                autoplay: false,
                autoplayControls: true
            });
        });
    }

    doc.on('submit','form.part_form:not(.ajax)', function(e) {
        $(this).find('button[type="submit"]').attr('disabled','disabled');
    });

    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }

    if($('body').is('[data-intranet-login-failed]')) {
        var loginFailedUrl = $('body').attr('data-intranet-login-failed');
        console.log(loginFailedUrl);

        var iButton = $('#intranetButton');

        if(loginFailedUrl) {
            var iButtonLink = iButton.attr('data-dialog');
            iButtonLink = iButtonLink + '&target=' + loginFailedUrl;
            iButton.attr('data-dialog',iButtonLink);
        }

        iButton.trigger('click');
    }

    $('form[data-append-filter]').antiSpam();

    if ($(".part_cookie_consent").length || $(".part_form_cookies").length) {
        $.getScript(cdnjs.cookieconsent);
    }
})(jQuery);