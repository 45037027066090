jQuery.getScript = function( url, options ) {
    if ($.getScript.loaded.indexOf(url) === -1) {
        $.getScript.loaded.push(url);
        options = $.extend( options || {}, {
            dataType: "script",
            cache: true,
            url: url
        });
        return jQuery.ajax(options);
    } else {
        return jQuery.ajax(options);
    }

};

jQuery.getScript.loaded = [];