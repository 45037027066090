(function($) {
    if (!html.hasClass("ie")) {
        $.fn.validation = function () {
            $(this).removeClass("mod--invalid mod--valid");
            if ($(this).find("input, textarea").val() !== "") {
                if ($(this).find("input, textarea").is(":valid")) {
                    $(this).addClass("mod--valid");
                } else {
                    $(this).addClass("mod--invalid");
                }
            }
        };

        doc.on("change", ".part_ui_input", function () {
            if (!$(this).filter("[data-no-validation]").length) {
                $(this).validation();
            }
        });
        $(".part_ui_input").each(function () {
            if (!$(this).filter("[data-no-validation]").length && !$(this).hasClass("mod--invalid")) {
                $(this).validation();
            }
        });


        let input_datepicker = $(".lib--datepicker");
        if (input_datepicker.length) {
            $.getScript(cdnjs.datepicker_main).done(function(){
                $.getScript(cdnjs.datepicker_lang.replace(".cs.","."+lang+".")).done(function () {
                    input_datepicker.find("input").datepicker({
                        language: lang,
                        autoClose: true,
                        dateFormat: 'dd.mm.yyyy'
                    });
                });
            });
        }
    }
})(jQuery);