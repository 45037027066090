(function($){
    const fn = $(".comp_base_reference");

    if (fn.length) {
        let slider = fn.find("[data-slider]");

        $.lib_flickity(function(){
            if(slider.children().length > 1) {
                let autoplay = slider.data("slider");

                slider.flickity({
                    imagesLoaded: false,
                    percentPosition: false,
                    setGallerySize: true,
                    pageDots: true,
                    wrapAround: true,
                    contain: true,
                    autoPlay: autoplay,
                    prevNextButtons: false,
                    pauseAutoPlayOnHover: false
                });

                slider.parent().find(".part_ui_arrow svg path").css("animation-duration",`${autoplay}ms`);

                slider.parent().on({
                    mouseenter: function () {
                        slider.flickity('pausePlayer');
                    },
                    mouseleave: function () {
                        slider.flickity('unpausePlayer');
                    }
                });

                slider.on('scroll.flickity', function () {
                    slider.parent().removeClass("autoplay--animated");
                });

                slider.on("settle.flickity",function () {
                    slider.parent().addClass("autoplay--animated");
                });


                fn.on("click", "[data-slider-prev]", function() {
                    slider.flickity('previous');
                });

                fn.on("click", "[data-slider-next]", function() {
                    slider.flickity('next');
                });
            }
        });
    }
})(jQuery);